import { ReactElement } from "react";
import { isDevelopmentEnvironment } from "../../../utils/StagingUtils";
import { CurrentState } from "./CurrentState";
import { ClearApp } from "./ClearApp";

export const StagingElements = () => {
  function render(): ReactElement {
    return isDevelopmentEnvironment ? (
      <div>
        <CurrentState />
        <ClearApp />
      </div>
    ) : (
      <span />
    );
  }

  return render();
};
