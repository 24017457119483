import { ReactElement } from "react";
import { useRecoilState } from "recoil";
import { AppState, recoilState } from "../persistence/Persistance";
import { Player } from "../persistence/model/Player";
import { Round } from "../persistence/model/Round";
import { MatchHelper } from "../persistence/helpers/MatchHelper";
import { PlayerHelper } from "../persistence/helpers/PlayerHelper";
import { RoundHelper } from "../persistence/helpers/RoundHelper";
import { States } from "../persistence/States";

const developmentUrls = ["hidden", "192.168."];
const websiteUrls = ["swissrounds"];
const productionUrls = ["localhost"];

const checkForDevelopmentEnvironment = () => {
  let development = false;
  developmentUrls.forEach((u) => {
    if (window.location.href.includes(u)) development = true;
  });
  return development;
};

const checkForWebsiteEnvironment = () => {
  let website = false;
  websiteUrls.forEach((u) => {
    if (window.location.href.includes(u)) website = true;
  });
  return website;
};

const checkForProductionEnvironment = () => {
  let production = false;
  productionUrls.forEach((u) => {
    if (window.location.href.includes(u)) production = true;
  });
  return production;
};

export const isDevelopmentEnvironment = checkForDevelopmentEnvironment();
export const isWebsiteEnvironment = checkForWebsiteEnvironment() && !isDevelopmentEnvironment;
export const isProductionEnvironment = checkForProductionEnvironment() && !isWebsiteEnvironment;

export function testPlayers(): Player[] {
  return isDevelopmentEnvironment
    ? [
        PlayerHelper.create("Player 1"),
        PlayerHelper.create("Player 2"),
        PlayerHelper.create("Player 3"),
        PlayerHelper.create("Player 4"),
        PlayerHelper.create("Player 5"),
      ]
    : [];
}

function setRandomWinners(players: Player[], round: Round): Round {
  let updatedMatches = round.matches.map((m) => {
    let one = PlayerHelper.getPlayerById(players, m.playerOneId);
    let two = PlayerHelper.getPlayerById(players, m.playerTwoId);

    if (one.name === "Player 5") {
      return MatchHelper.createFinishedMatch(m, one.id, two.id);
    } else if (two.name === "Player 5") {
      return MatchHelper.createFinishedMatch(m, two.id, one.id);
    }

    let random = Math.random();

    if (random >= 0.66) {
      return MatchHelper.createFinishedMatch(m, one.id, two.id);
    } else if (random >= 0.33) {
      return MatchHelper.createFinishedMatch(m, two.id, one.id);
    }

    // else it's a draw
    return MatchHelper.createFinishedMatch(m);
  });

  return {
    id: round.id,
    matches: updatedMatches,
    bye: round.bye,
  };
}

export const SimulateRoundComponent = () => {
  const [appState, setAppState] = useRecoilState<AppState>(recoilState);

  function render(): ReactElement {
    return isDevelopmentEnvironment ? <button onClick={handleSimulateRound}>Simulate round</button> : <span />;
  }

  function handleSimulateRound(): void {
    let players = appState.tournament.players;
    let round = RoundHelper.getCurrentRound(appState.tournament);

    let simmulatedRound = setRandomWinners(players, round);
    let updatedRounds = RoundHelper.updateRound(appState.tournament.rounds, simmulatedRound);

    setAppState({
      state: States.ROUND_PLAYING,
      tournament: {
        players: players,
        rounds: updatedRounds,
      },
    });
  }

  return render();
};
