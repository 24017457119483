import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { AppState, recoilState } from "../../persistence/Persistance";
import { isProductionEnvironment } from "../../utils/StagingUtils";
import ReactGA from "react-ga4";

export const Analytics = () => {
  const trackingIdProduction = "G-VHL8Z7JCP7";
  const trackingIdStaging = "G-8ZYH37DDL2";
  const trackingId = isProductionEnvironment ? trackingIdProduction : trackingIdStaging;
  ReactGA.initialize(trackingId);

  const appState = useRecoilValue<AppState>(recoilState);

  useEffect((): void => {
    recordState(appState.state);
  }, [appState]);

  function recordState(state: string) {
    ReactGA.event({
      category: "Navigation",
      action: "Navigate to " + state,
    });

    ReactGA.send({ hitType: "pageview", page: state });
  }

  return <span />;
};
