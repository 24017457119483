import { ReactElement } from "react";
import iosAppstoreImage from "../../assets/ios_appstore.png";

export const LandingPageView = () => {
  const IOSUrl = "itms-apps://apps.apple.com/nl/app/swiss-rounds/id6702020560";

  function render(): ReactElement {
    return (
      <div className="page bg-hp360">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1>
                <span className="preheader hp360">HP</span>
                <br />
                <span className="logo">360</span>
              </h1>
            </div>
          </div>
          <div className="content-section">
            <h1 className="standings">
              <br />
              Swiss <br />
              Rounds <br />
            </h1>
          </div>

          <br />
          <div>
            <img src={iosAppstoreImage} alt="Open on App Store" className="ios-appstore-button" onClick={handleOnIosAppstoreImageClick} />
          </div>
        </div>
      </div>
    );
  }

  function handleOnIosAppstoreImageClick() {
    window.open(IOSUrl, "_blank");
  }

  return render();
};
