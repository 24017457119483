import { ReactElement } from "react";

export const ClearApp = () => {
  function render(): ReactElement {
    return (
      <div className="staging bg-staging link" onClick={handleClearApp}>
        <div className="subnav-button edit link">Clear App</div>
      </div>
    );
  }

  function handleClearApp() {
    window.localStorage.clear();
    window.location.reload();
  }

  return render();
};
