import { ReactElement } from "react";
import { useSetRecoilState } from "recoil";
import { AppState, recoilState } from "../../../persistence/Persistance";
import { States } from "../../../persistence/States";
import { testPlayers } from "../../../utils/StagingUtils";

export const TournamentCreateView = () => {
  const setAppState = useSetRecoilState<AppState>(recoilState);

  function render(): ReactElement {
    return (
      <div className="page bg-hp360">
        <div className="top-wrapper">
          <div className="title-and-subnav">
            <div className="title">
              <h1>
                <span className="preheader hp360">HP</span>
                <br />
                <span className="logo">360</span>
              </h1>
            </div>
          </div>
          <div className="content-section">
            <h1 className="standings">
              <br />
              Swiss <br />
              Rounds <br />
            </h1>
          </div>
        </div>
        <div className="bottom-navigation single">
          <h1 className="link next" onClick={handleCreateTournament}>
            <br />
            Start
          </h1>
        </div>
      </div>
    );
  }

  function handleCreateTournament(): void {
    setAppState({
      state: States.PLAYERS_ADD,
      tournament: {
        players: testPlayers(),
        rounds: [],
      },
    });
  }

  return render();
};
